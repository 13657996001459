<template>
    <div>

        <el-main style=" padding-top: 5px;padding-bottom: initial  ">
            <el-form :model="formData" label-width="80px" @submit.native.prevent>
                <el-row>
                    <el-col :lg="24">
                        <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                            <el-row>
                                <el-col :lg="24" style="line-height: 27px;">
                                    <strong style=" font-size: 16px; ">{{this.$store.getters.getRunTimeTest ?'(测试环境,数据定期删除)':''}}当前：开单通知书</strong>
                                    <el-checkbox v-if="isMobile" style="padding-left: 25px" v-model="showFrom">显示表单</el-checkbox>
                                    <strong class="pull-right" style=" font-size: 16px;color: red ">{{formData.is_cancel?'已作废':''}}</strong>
                                </el-col>
                            </el-row>
                        </div>
                        <el-row v-show="showFrom">
                            <el-col :lg="5" :sm="12" :md="8">
                                <el-form-item label="客户:">
                                    <el-select ref="customer" v-model="formData.customer" style="width: 100%"
                                               @keyup.native.enter="$_blurNext('customer'),$_focusNext('settlement_days')"
                                               @change="isDataChanage=true"
                                               placeholder="可搜索查询"
                                               @visible-change="(v) => $refs.AddCompany.visibleChange(v, this.$refs['customer'])"
                                               default-first-option remote :remote-method="$_searchCustomerList"
                                               clearable
                                               filterable size="mini">
                                        <el-option
                                                v-for="item in $store.getters.getCustomerList"
                                                :key="item.id"
                                                :label="item.customer_name"
                                                :value="item.customer_name">
                                            <span style="float: left">{{ item.customer_name}}</span>
                                            <span v-if="$_getActionKeyListBoolean(['/customers/editVue'])"
                                                  style="float: right"><i
                                                    class="el-icon-edit-outline__close el-icon-edit-outline"
                                                    @click.stop="$_blurNext('customer'),$refs.AddCompany.editRowEvent(item.id)"/></span>
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <AddCompany ref="AddCompany"></AddCompany>
                            <el-col :lg="3" :sm="12" :md="8">
                                <el-form-item label="日期:">
                                    <el-date-picker
                                            v-model="formData.bill_date_kdtzs "
                                            ref="bill_date_kdtzs "
                                            align="right"
                                            type="date"
                                            size="mini"
                                            @change="$_focusNext('clearing_form'),isDataChanage=true"
                                            @keyup.native.enter="$_focusNext('clearing_form')"
                                            :clearable="false"
                                            style="width: 130px">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="5" :sm="12" :md="8">
                                <el-tooltip class="item" effect="dark"
                                            :content="`创建日期:${formData.creat_date_warehouse_bill}`" placement="bottom-end" :open-delay = "750">
                                    <el-form-item label="开单通知书-单号:" label-width="180px">
                                        <el-input style="width: 170px;" ref="bill_id_kdtzs" v-model="formData.bill_id_kdtzs"
                                                  :readonly="true" placeholder="自动生成"
                                                  @keyup.native.enter="$_focusNext('bill_id_kd')"
                                                  size="mini"></el-input>
                                    </el-form-item>
                                </el-tooltip>
                            </el-col>
                            <el-col :lg="5" :sm="12" :md="8">
                                <el-form-item label="销售开单-单号:" label-width="180px">
                                    <el-input style="width: 170px;" ref="bill_id_kd" v-model="formData.bill_id_kd"
                                              :readonly="true" placeholder="可生成"
                                              @keyup.native.enter="$_focusNext('settlement_days')"
                                              size="mini"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="5" :sm="12" :md="8">
                                <el-form-item label="发货单-单号:" label-width="180px">
                                    <el-input style="width: 170px;" ref="bill_id_fh" v-model="formData.bill_id_fh"
                                              :readonly="true" placeholder="可生成"
                                              size="mini"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :sm="12" :md="2">
                                <el-form-item label="结算天数:" label-width="72px">
                                    <vxe-input style="width: 55px" ref="settlement_days"
                                               v-model="formData.settlement_days"
                                               type="number" size="mini"
                                               @change="isDataChanage=true"
                                               @keyup.native.enter="$_focusNext('clearing_form')">
                                    </vxe-input>
                                </el-form-item>
                            </el-col>

                            <el-col :sm="12" :md="3">
                                <el-form-item label="结算方式:">
                                    <el-select ref="clearing_form" v-model="formData.clearing_form" placeholder="可搜索查询"
                                               style="width: 100px"
                                               size="mini"
                                               @change="isDataChanage=true"
                                               @keyup.native.enter="$_blurNext('clearing_form'),$_focusNext('tax_tate_warehouse_bill')"
                                               filterable remote :remote-method="$_getClearingFormList">
                                        <el-option
                                                v-for="item in $store.getters.getClearingFormList"
                                                :key="item.pay_name"
                                                :label="item.pay_name"
                                                :value="item.pay_name">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :sm="12" :md="3">
                                <el-form-item label="税率:" label-width="50px">
                                    <el-select
                                            ref="tax_tate_warehouse_bill"
                                            v-model="formData.tax_tate_warehouse_bill"
                                            @keyup.native.enter="$_blurNext('tax_tate_warehouse_bill'),$_focusNext('car_code')"
                                            allow-create
                                            filterable
                                            size="mini" clearable
                                            default-first-option
                                            placeholder="可输入、可选择">
                                        <el-option
                                                v-for="item in taxTateWarehouseBillList"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>

                            <el-col :sm="12" :md="3">
                                <el-form-item label="业务员:">
                                    <el-select ref="sales_man" v-model="formData.sales_man" clearable
                                               placeholder="可搜索查询"
                                               size="mini"
                                               @change="isDataChanage=true"
                                               @focus="$_salesManListFilter"
                                               :filter-method="$_salesManListFilter"
                                               default-first-option
                                               @keyup.native.enter="$_blurNext('sales_man'),$_focusNext('car_code')"
                                               filterable>
                                        <el-option
                                                v-for="item in $store.getters.getSalesManList"
                                                :key="item.nick_name"
                                                :label="item.nick_name"
                                                :value="item.nick_name">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :sm="12" :md="6">
                                <el-form-item label="司机、车号:" label-width="100px">
                                    <el-input ref="car_code" v-model="formData.car_code"
                                              @change="isDataChanage=true"
                                              @keyup.native.enter="$_focusNext('remark_warehouse_bill')"
                                              placeholder="请输入" size="mini"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :sm="12" :md="6">
                                <el-form-item label="开单备注:">
                                    <el-select
                                            ref="remark_warehouse_bill" v-model="formData.remark_warehouse_bill"
                                            style="width: 100%"
                                            size="mini"
                                            multiple
                                            filterable
                                            allow-create
                                            clearable
                                            @change="isDataChanage=true,selectChange()"
                                            @keyup.native.enter="$_blurNext('remark_warehouse_bill'),$_focusNext('part_order')"
                                            @blur.native.capture="handleRemarkWarehouseBillBlur"
                                            placeholder="可以输入、可选多选">
                                        <el-option
                                                v-for="item in remarkWarehouseBillList"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="3" :sm="12" :md="8">
                                <el-form-item label="开单员:">
                                    <el-select ref="part_order" v-model="formData.part_order" clearable
                                               placeholder="可搜索查询"
                                               size="mini"
                                               @change="isDataChanage=true"
                                               @focus="$_partOrderListFilter"
                                               default-first-option
                                               :filter-method="$_partOrderListFilter"
                                               @keyup.native.enter="$_blurNext('part_order'),$refs.xGrid.setActiveCell(tableDataMain[0],'specification')"
                                               filterable>
                                        <el-option
                                                v-for="item in $store.getters.getPartOrderList"
                                                :key="item.nick_name"
                                                :label="item.nick_name"
                                                :value="item.nick_name">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="3" :sm="12" :md="8">
                                <el-form-item label="总理论重:">
                                    <el-input :readonly="true" placeholder="自动计算"
                                              v-model="formData.total_theoretical_weight" size="mini"></el-input>
                                </el-form-item>
                            </el-col>

                            <el-col :lg="3" :sm="12" :md="8">
                                <el-tooltip class="item" effect="dark" placement="top" :open-delay="750">
                                    <div slot="content">
                                        修改人:{{formData.last_name_warehouse_bill ? formData.last_name_warehouse_bill :
                                        ''}}<br>
                                        修改时间:{{formData.last_date_warehouse_bill ? formData.last_date_warehouse_bill :
                                        ''}}<br>
                                        创建时间:{{formData.creat_date_warehouse_bill ? formData.creat_date_warehouse_bill :
                                        ''}}
                                    </div>
                                    <el-form-item label="记录员:">
                                        <el-input ref="registrar" v-model="formData.registrar" :readonly="true"
                                                  size="mini"
                                                  @keyup.native.enter="$_focusNext('last_date_warehouse_bill')"></el-input>
                                    </el-form-item>
                                </el-tooltip>
                            </el-col>
                            <el-col :lg="4" :sm="12" :md="8">
                                <el-tooltip class="item" effect="dark" placement="top" :open-delay="750">
                                    <div slot="content">
                                        打印次数:{{formData.print_count ? formData.print_count : ''}}<br>
                                    </div>
                                    <el-form-item label="打印日期:">
                                        <el-input :readonly="true"
                                                  v-model="formData.print_time" size="mini"></el-input>
                                    </el-form-item>
                                </el-tooltip>
                            </el-col>
                            <el-col :lg="5" :sm="12" :md="8">
                                <el-form-item label="销售订单-单号:" label-width="120px">
                                    <el-input style="width: 170px;" ref="bill_id_dd" v-model="formData.bill_id_dd"
                                              :readonly="true" placeholder="可生成"
                                              size="mini"></el-input>
                                </el-form-item>
                            </el-col>

                            <el-col :lg="11" :sm="12" :md="8">
                                <el-button style=" margin-top: 12px; margin-right: 12px;" type="success"
                                           round @click="$router.push('/transferSlipInform')" size="mini">新建
                                </el-button>
                                <el-button type="primary" round
                                           @click="PrintTransferSlipInform" size="mini">打印预览
                                </el-button>


                                <el-button type="primary" round :disabled="formData.is_cancel"
                                           v-if="$_getActionKeyListBoolean(['/transferSlip/buildBillByTransferSlipService']) && this.isBlankVue(formData.bill_id_kd)"
                                           @click="$_createBillByTransferSlip" size="mini">生成《销售开单》
                                </el-button>
                                <el-button type="primary" round :disabled="formData.is_cancel"
                                           v-if="!this.isBlankVue(formData.bill_id_kd)"
                                           @click="$_createBillByTransferSlip" size="mini">查看《销售开单》
                                </el-button>

                                <el-button style=" margin-top: 12px;" type="primary" round
                                           :disabled="formData.is_cancel"
                                           v-if="$_getActionKeyListBoolean(['/shippingAdvice/buildBillByShippingAdviceService']) && this.isBlankVue(formData.bill_id_fh)"
                                           @click="$_createBillByPrintShippingAdvice('KDTZS')" size="mini">生成《发货单》
                                </el-button>
                                <el-button style=" margin-top: 12px;" type="primary" round
                                           :disabled="formData.is_cancel" v-if="!this.isBlankVue(formData.bill_id_fh)"
                                           @click="$_createBillByPrintShippingAdvice('KDTZS')" size="mini">查看《发货单》
                                </el-button>

                                <el-button type="primary" size="mini" @click="$router.back()"
                                           style="margin-bottom: 2px;margin-top: 2px">返回
                                </el-button>
                                <el-button type="danger" plain round style=" margin-top: 12px; margin-right: 12px;"
                                           :disabled="this.loading || this.formData.is_cancel"
                                           @click="onSubmit" size="mini">保存
                                </el-button>
                                <vxe-toolbar class="pull-left"
                                             style=" margin-top: 5px;margin-right: 12px;height: 42px; " custom
                                             ref="xToolbar2">
                                </vxe-toolbar>
                            </el-col>


                        </el-row>
                        <el-row hidden>
                            <el-col :lg="3" :sm="12" :md="8">
                                <el-form-item label="合计支数:">
                                    <el-input :readonly="true" placeholder="自动计算" v-model="formData.total_number"
                                              size="mini"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="3" :sm="12" :md="8">
                                <el-form-item label="总理论重:">
                                    <el-input :readonly="true" placeholder="自动计算"
                                              v-model="formData.total_theoretical_weight" size="mini"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="3" :sm="12" :md="8">
                                <el-form-item label="合计金额:">
                                    <el-input :readonly="true" placeholder="自动计算"
                                              v-model="formData.total_amount" size="mini"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="3" :sm="12" :md="8">
                                <el-form-item label="总过磅重:">
                                    <el-input :readonly="true" placeholder="自动计算"
                                              v-model="formData.total_weighing_heavy"
                                              size="mini"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="3" :sm="12" :md="8">
                                <el-form-item label="合计实收:">
                                    <el-input :readonly="true" placeholder="自动计算"
                                              v-model="formData.practical_total_price"
                                              size="mini"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="3" :sm="12" :md="8">
                                <el-form-item label="合计佣金:">
                                    <el-input :readonly="true" placeholder="自动计算" v-model="formData.kick_back_total"
                                              size="mini"></el-input>
                                </el-form-item>
                            </el-col>

                        </el-row>
                    </el-col>


                </el-row>
                <el-row>
                    <el-col :lg="24">
                        <MnGrid ref="xGrid" :height="(this.getViewHeight() - 250)+''" id="TransferSlipInformId"
                                :table-column="tableColumn" current-page-name="KDTZS" :table-data-main="tableDataMain"
                                :form-data="formData"></MnGrid>
                    </el-col>
                </el-row>
            </el-form>
        </el-main>
    </div>
</template>

<script>

    import MnGrid from "../../components/MnGrid";
    import AddCompany from "../../components/AddCompany";

    export default {
        components: {AddCompany, MnGrid},
        name: "TransferSlipInform",
        data() {
            return {
                pageType: '开单通知书',
                filterData: {
                    customer_name: '',
                    state: "激活"
                },
                remarkWarehouseBillList: [
                    {value: '实重', label: '实重'},
                    {value: '等款', label: '等款'},
                    {value: '费用入单', label: '费用入单'},
                    {value: '理计', label: '理计'},
                    {value: '发单', label: '发单'}
                ],//备注，可以多选
                loading: false,
                clearingFormList: [],
                otherExpensesList: [],
                start_date: '',
                isAddBill: true,
                isDataChanage: false,
                tableColumn: [
                    {type: 'seq', title: '序号', className: 'unEditor', width: 40},
                    {
                        field: 'specification',
                        width: 150,
                        title: '规格',
                        slots: {edit: 'specification_pulldown_edit'},
                        editRender: {autofocus: 'input.vxe-input--inner'}
                    },
                    {
                        field: 'category',
                        visible: true,
                        width: 50,
                        title: '类别',
                        slots: {edit: 'category_edit'},
                        editRender: {autofocus: 'input.el-input__inner'}
                    },
                    {
                        field: 'unit',
                        width: 45,
                        title: '单位',
                        slots: {edit: 'unit_edit'},
                        editRender: {autofocus: 'input.el-input__inner'}
                    },
                    {
                        field: 'single_count_str', width: 90, title: '支数(可文本)',
                        slots: {edit: 'single_count_str_edit'},
                        editRender: {autofocus: 'input.el-input__inner'}
                    },

                    {
                        field: 'price', width: 70, title: '单价',
                        titleHelp: {message: '单位(吨) 单价= 吨价\n单位(支) 单价= 每支单价'},
                        slots: {edit: 'price_edit'},
                        editRender: {autofocus: 'input.el-input__inner'}
                    },
                    /*{
                        field:'price', width: 100, title: '单价',
                        titleHelp: {message: '单位(吨) 单价= 吨价\n单位(支) 单价= 每支单价'},
                        editRender: {
                            name: 'ElInputNumber', props: {controls: false},
                            events: {change: this.setPrice}
                       }
                   },*/


                    /* {
                         field:'actual_thickness', width: 60, title: '实厚',
                         slots: {edit: 'actual_thickness_edit'},
                         editRender: {autofocus: 'input.el-input__inner'}
                    },*/
                    {
                        field: 'remark_stock',
                        width: 155,
                        title: '产品备注',
                        editRender: {name: 'input', props: {controls: false}}
                    },
                    {
                        field: 'warehouse_out',
                        visible: true,
                        width: 60,
                        title: '调出仓库',
                        slots: {edit: 'warehouse_out_edit'},
                        editRender: {autofocus: 'input.el-input__inner'}
                    },
                    {
                        field: 'single_price_practical', width: 70, title: '实收单价',
                        slots: {edit: 'single_price_practical_edit'},
                        editRender: {autofocus: 'input.el-input__inner'}
                    },
                    {
                        field: 'full_thick',
                        width: 50,
                        title: '足厚',
                        slots: {edit: 'full_thick_edit'},
                        editRender: {autofocus: 'input.el-input__inner'}
                    },
                    {
                        field: 'theory_weight',
                        width: 50,
                        title: '理计',
                        slots: {edit: 'theory_weight_edit'},
                        editRender: {autofocus: 'input.el-input__inner'}
                    },

                    {
                        field: 'stock_bill_date_dd',
                        visible: true,
                        title: '订单日期',
                        className: 'unEditor',
                        formatter: ({cellValue}) => {
                            return this.formatDate(cellValue, 'yyyy-MM-dd HH:mm:ss')
                        },
                        width: 170
                    },

                    {
                        field:'single_count', width: 55, title: '支数',className: 'unEditor',
                    },
                    {
                        field: 'weighing_heavy',
                        width: 74,
                        title: '过磅重(吨)',
                        visible: true,
                        titleHelp: {message: '过磅重=理论支重 * 支数\n可以自行修改\n(吨)'},
                        slots: {edit: 'weighing_heavy_edit'},
                        editRender: {autofocus: 'input.el-input__inner',enabled:true}
                    },
                    {
                        field: 'theoretical_weight',
                        width: 90,
                        className: 'unEditor',
                        titleHelp: {message: '理论重量 = 理论支重 * 支数\n(吨)'},
                        title: '理论重量'
                    },

                    {field: 'total_count', visible: true, className: 'unEditor', width: 50, title: '库存'},
                    {field: 'warehouse4', visible: true, className: 'unEditor', width: 50, title: '厂一'},
                    {field: 'warehouse5', visible: true, className: 'unEditor', width: 50, title: '厂二'},
                    {field: 'warehouse1', visible: true, className: 'unEditor', width: 50, title: '仓一'},
                    {field: 'warehouse2', visible: true, className: 'unEditor', width: 50, title: '仓二'},
                    {field: 'warehouse3', visible: true, className: 'unEditor', width: 50, title: '仓三'},
                    {field: 'warehouse9', visible: true, className: 'unEditor', width: 50, title: '仓四'},
                    {field: 'warehouse7', visible: true, className: 'unEditor', width: 50, title: '新门市'},
                    {field: 'dd_stock_id', visible: true, className: 'unEditor', width: 85, title: '销售订单规格ID'},
                    {
                        field: 'warehouse_out_id', visible: false,
                        width: 65,
                        className: 'unEditor',
                        title: '调出仓库Id'
                    },


                    /* {
                         field:'weighing_heavy',
                         width: 65,
                         visible: false,
                         titleHelp: {message: '过磅重=理论支重*支数\n可以自行修改\n(吨)'},
                         title: '过磅重',
                         editRender: {
                             name: 'ElInputNumber',
                             props: {controls: false},
                             events: {change: this.$_singleCountEvent}
                        }
                    },*/

                    /*{
                        field:'actual_thickness', visible:false,width: 45,
                        title: '实厚',
                        editRender: {name: 'ElInputNumber', props: {controls: false}}
                   },*/
                    {
                        field: 'single_price', visible: false, className: 'unEditor', width: 70, title: '每支单价',
                        titleHelp: {message: '(自动判断 单位:支)每支单价=单价'}
                    },
                    {
                        field: 'tons_of_price', visible: false, className: 'unEditor', width: 60, title: '吨价',
                        titleHelp: {message: '当：单位（支）\n吨价 = 产品金额 ÷ 过磅重\n当：单位（吨）\n吨价 = 单价'}
                    },
                    {
                        field: 'texture', visible: false, title: '材质', width: 100,
                        editRender: {name: 'input'}
                    }, {
                        field: 'single_weight',
                        visible: false,
                        className: 'unEditor',
                        width: 50,
                        titleHelp: {message: '支重=过磅重/支数\n(kg)'},
                        title: '支重'
                    },

                    {
                        field: 'theoretical_weight_single',
                        visible: false,
                        width: 90, className: 'unEditor',
                        titleHelp: {message: '理论支重=[(边长a+边长b)*2-4*壁厚]*壁厚*0.00785*长度\n(吨)'},
                        title: '理论支重'
                    },

                    {
                        field: 'amount_of_product',
                        width: 70, className: 'unEditor',
                        visible: false,
                        title: '产品金额',
                        titleHelp: {message: '单位(吨) 产品金额= 过磅重*吨价\n单位(支) 产品金额= 支数*每支单价'}
                    },
                    {
                        field: 'kick_back',
                        visible: false,
                        width: 50,
                        title: '佣金',
                        className: 'unEditor',
                        titleHelp: {message: '佣金=产品金额-实收金额'},
                    }, {
                        field: 'practical_price',
                        width: 70,
                        className: 'unEditor',
                        visible: false,
                        title: '实收金额',
                        titleHelp: {message: '实收金额=实收单价*支数(数量)'},
                    },

                    {field: 'operate', title: '操作', fixed: 'right', width: 35, slots: {default: 'operate'}}
                ],
                tableDataMain: [],
                specification: [],
                categoryList: [],
                unitList: [],
                billingCompanyList: [],
                accountList: [],
                warehouseList: [],
                activeName: 'first',
                checked: false,
                tableData: [],
                regionList: [],
                formData: {
                    clearing_form: '欠款',
                    other_expenses: '费用',
                    other_expenses_price: '',
                    customer_phone: '',
                    part_order: '',
                    car_code: '',
                    bill_date: '',
                    bill_date_kd: '',
                    bill_date_dd: '',
                    bill_date_kdtzs: new Date(),
                    contract_id: '',
                    delivery_date: '',
                    kick_back: '',
                    total_theoretical_weight: '',
                    bill_id_dd: '',
                    bill_id_kd: '',
                    bill_id_fh: '',
                    bill_id_kdtzs: '',
                    bill_id: '',
                    bill_id_type: 'KDTZS',
                    settlement_days: '',
                    customer: '',
                    sales_man: '',
                    billing_company_name: '',
                    remark_warehouse_bill: [],
                    registrar: '',
                    total_amount: '',
                    practical_total_price: '',
                    total_number: '',
                    is_ticket: '',
                    last_name_warehouse_bill: '',
                    last_date_warehouse_bill: '',
                    bill_type: '开单通知书',
                    creat_date_warehouse_bill: '',
                    handled_by: '',
                    consignee: '',
                    tax_tate_warehouse_bill: '',
                    supplier: '',
                    additional_charges: '',
                    amount_of_product: '',
                    cost_of_the_unit: '',
                    total_weighing_heavy: '',
                    pounds_fees: '',//磅费
                    wind_cut_fees: '',//风割费
                    loading_car_fees: '',//装车费
                    transfer_fees: '',//中转费
                    freight_fees: '',//运费
                    taxes_fees: '',//税金
                    is_cancel: false,
                    print_time: '',//打印日期
                    print_count: '',//打印次数
                },
                //（板）（足方）（足板）
                taxTateWarehouseBillList: [
                    {label: '(方)', value: '(方)'},
                    {label: '(板)', value: '(板)'},
                    {label: '(足方)',value: '(足方)'}
                ]
            }
        },
        methods: {
            selectChange(){
                //console.log('change')
                //console.log(this.$refs['remark_warehouse_bill'])
                let _this = this;
                setTimeout(function() {
                    _this.$refs['remark_warehouse_bill'].blur()
                }, 50);
            },
            handleRemarkWarehouseBillBlur() {
                //console.log('test');
                let query = this.$refs.remark_warehouse_bill.query;
                //console.log(this.$refs.remark_warehouse_bill.query);
                if (null != query && query.length > 0) {
                    //let item = this.formData.remark_warehouse_bill.find(el => el == query);
                    this.formData.remark_warehouse_bill.push(query);
                    this.$_blurNext('remark_warehouse_bill');
                    /*if (item == undefined) {
                        //this.$refs.remark_warehouse_bill.clearSingleSelect();
                        this.formData.remark_warehouse_bill.push(query);
                   } else {
                        this.username = user.key;
                        this.password = user.value;
                   }*/
                }
            },
            searchWarehouseBill() {
                const loading = this.$loading({
                    lock: true,
                    text: '饮茶先啦，数据处理中，请耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                this.loading = true;
                this.$axios({
                    method: 'post',
                    url: '/admin/transferSlipInform/search',
                    params: {
                        bill_id_kdtzs: this.formData.bill_id_kdtzs,
                    },
                }).then((response) => {          //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    loading.close();
                    this.loading = false;
                    if (response.status === 200 && response.data.state == 'ok') {
                        this.isAddBill = false;
                        this.formData = response.data.warehouseBill;
                        if (!this.isBlankVue(this.formData.remark_warehouse_bill)) {
                            this.formData.remark_warehouse_bill = this.formData.remark_warehouse_bill.toString().replace('，', ',').split(',');
                        }
                        this.tableDataMain = this.$_createRowData(response.data.stocks);
                    } else {
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                        });
                        return
                    }
                }).catch((error) => {
                    loading.close();
                    console.log(error)
                });
            },
            searchWarehouseBillByDd() {
                this.$axios({
                    method: 'post',
                    url: '/admin/salesOrdersSingle/search',
                    params: {
                        bill_id_dd: this.formData.bill_id_dd,
                    },
                }).then((response) => {         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200 && response.data.state == 'ok') {
                        this.formData = response.data.warehouseBill;
                        if (!this.isBlankVue(this.formData.remark_warehouse_bill)) {
                            this.formData.remark_warehouse_bill = this.formData.remark_warehouse_bill.toString().replace('，', ',').split(',');
                        }
                        this.tableDataMain = this.$_createRowData(response.data.stocks);
                        //遍历处理 清空 stock id 用于 开单通知书保存
                        for (let i = 0; i < this.tableDataMain.length; i++) {
                            //清空实厚
                            this.tableDataMain[i].dd_stock_id = this.tableDataMain[i].id;
                            this.tableDataMain[i].id = '';
                            this.tableDataMain[i].single_count_str = this.$XEUtils.subtract(this.tableDataMain[i].single_count, this.tableDataMain[i].dd_is_ok_count);
                            if (this.tableDataMain[i].single_count_str == 0) {
                                this.tableDataMain[i].single_count_str = '';
                            }
                        }
                        //清空bill Id
                        this.formData.id = '';
                        //开单日期今天
                        this.formData.bill_date_kdtzs = new Date();
                        //记录员
                        this.formData.registrar = this.$store.getters.getLoginAccount.nick_name
                    } else {
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                        });
                        return
                    }
                }).catch((error) => {
                    console.log(error)
                });
            },
            //打印开单通知书
            PrintTransferSlipInform() {
                if (this.formData.bill_id_kdtzs != null && this.formData.bill_id_kdtzs != '') {
                    //console.log(this.$_isGridDataChange(this.$refs['xGrid'].getBaseGird));//this.$refs['xGrid']
                    if (this.$_isGridDataChange(this.$refs['xGrid']) || this.isDataChanage) {
                        this.$message({
                            showClose: true,
                            message: '当前数据已被修改，请先保存，再打印！',
                            type: 'error'
                        });
                    } else {
                        this.$router.push({
                            path: "/printTransferSlipInform",
                            query: {bill_id_kdtzs: this.formData.bill_id_kdtzs}
                        })
                    }
                } else {
                    this.$message({
                        showClose: true,
                        message: '打印前，请先保存单据，单号不能为空！',
                        type: 'error'
                    });
                }
            },


            /*querySearch(queryString, cb) {
                var restaurants = this.taxTateWarehouseBillList;
                var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
                // 调用 callback 返回建议列表的数据
                cb(results);
           },
            createFilter(queryString) {
                return (restaurant) => {
                    return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
               };
           },*/
            findTaxTateWarehouseBillList(query) {
                this.$axios({
                    method: 'post',
                    url: '/admin/transferSlipInform/findTaxTateWarehouseBillList',
                    data: {
                        keyword: query
                    }
                }).then((response) => {         //这里使用了ES6的语法
                    if (response.status == 200 && response.data.state == "ok") {
                        this.taxTateWarehouseBillList = response.data.list
                    } else {
                        return
                    }
                }).catch((error) => {
                    console.log(error)
                });
            },
            onSubmit() {
                this.loading = true
                const loading = this.$loading({
                    lock: true,
                    text: '饮茶先啦，数据处理中，请耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                this.isDataChanage = false;
                let url = '';
                if (this.isAddBill) {
                    url = '/admin/transferSlipInform/saveVue';
                } else {
                    url = '/admin/transferSlipInform/updateVue';
                }
                this.formData.remark_warehouse_bill = this.formData.remark_warehouse_bill.toString();
                this.$axios({
                    method: 'post',
                    url: url,
                    data: {
                        formData: this.formData,
                        tableDataMain: this.tableDataMain,
                    },
                }).then((response) => {          //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    this.loading = false
                    loading.close();
                    if (response.status === 200 && response.data.state == 'ok') {
                        this.$message({
                            showClose: true,
                            message: '保存成功',
                            type: 'success'
                        });
                        if (this.isBlankVue(this.formData.bill_id_kdtzs)) {
                            this.$router.push({
                                path: "/transferSlipInform",
                                query: {bill_id_kdtzs: response.data.warehouseBill.bill_id_kdtzs}
                            })
                        } else {
                            this.searchWarehouseBill();
                            //this.showCustomerEdit = false;
                            //this.isAddBill = false;
                        }
                        //this.formData = response.data.warehouseBill;
                        //this.tableDataMain = this.$_createRowData(response.data.stocks);
                    } else {
                        this.loading = false
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                        });
                    }
                }).catch((error) => {
                    this.loading = false
                    console.log(error)
                    loading.close();
                    this.$message({
                        showClose: true,
                        message: '网络超时，请重试！',
                        type: 'error'
                    });
                });
            }
        }, computed: {
            // 计算属性的 getter
            billName: function () {
                // `this` 指向 vm 实例
                if (this.formData.clearing_form === '欠款') {
                    return '欠款单';
                } else {
                    return '调拨单';
                }
            }
        },
        created() {
            const list1 = []
            list1.push({label: '（方）', value: '（方）'})
            list1.push({label: '（板）', value: '（板）'})
            list1.push({label: '（足方）', value: '（足方）'})
            //list1.push({label: '（足板）', value: '（足板）'})
            this.formData.bill_id_kdtzs = this.$route.query.bill_id_kdtzs;
            this.formData.bill_id_dd = this.$route.query.bill_id_dd;
            this.$_searchCustomerList('');
            this.$_searchCategoryList('');
            this.$_searchAccountList('');
            this.$_getBillingCompanySettingList('');
            this.$_getClearingFormList('');
            this.$_getOtherExpensesList('');
            this.$_getAccountListByRoleId('10');//业务员
            this.$_getAccountListByRoleId('14');//开单员
            this.$_searchUnitList('');
            var list = [];
            if (this.formData.bill_id_dd != null && this.formData.bill_id_dd != '') {
                this.searchWarehouseBillByDd();
            } else if (this.formData.bill_id_kdtzs == null || this.formData.bill_id_kdtzs == '') {
                this.tableDataMain = this.$_createRowData(list);
            } else {
                this.searchWarehouseBill();
            }
            //如果记录员(业务员)为空，则自动填写当前账户名字
            if (this.formData.registrar == null || this.formData.registrar == '') {
                this.formData.registrar = this.$store.getters.getLoginAccount.nick_name
            }
            if (this.formData.bill_id_kdtzs == null || this.formData.bill_id_kdtzs == '') {
                this.formData.sales_man = this.$store.getters.getLoginAccount.nick_name
            }
            this.$nextTick(() => {
                // 手动将表格和工具栏进行关联
                this.$refs.xGrid.connect(this.$refs.xToolbar2)
                this.findTaxTateWarehouseBillList('');
            })
        }
    };


</script>

<style scoped>
    /deep/ table .el-input--mini .el-input__inner{
        height: 30px;
        line-height: 30px;
    }
    .input-with-select .el-input .el-input--mini .el-input-group .el-input-group--prepend .el-input-group__prepend {
        width: 80px !important;
    }

    .vxe-table--render-default .vxe-cell {
        padding-left: initial !important;
        padding-right: initial !important;
    }

    .el-date-editor.el-input, .el-date-editor.el-input__inner {
        width: 128px !important;
    }

    .el-form-item__content .el-input-group {
        vertical-align: initial;
    }

    /deep/ .vxe-input--number-suffix {
        display: none;
    }


    /deep/ .vxe-input--suffix {
        height: auto;
    }

    /deep/ .vxe-input .vxe-input--suffix {
        width: auto;
    }

    /deep/ .vxe-input.is--controls.type--number .vxe-input--suffix {
        right: 0.6em;
    }

</style>
